<template>
  <div>
    <ValidationObserver ref="recipientDetails">
      <b-card>
        <b-row class="d-flex align-items-center">
          <b-col md="5">
            <b-form-group>
              <ValidationProvider v-slot="{ errors }" rules="required" name="Lorry Size">
                <slot name="label">
                  Lorry Size
                  <feather-icon
                    :id="`weight-tooltip-${index}`"
                    size="20"
                    icon="InfoIcon"
                    style="margin-bottom: 5px"
                  />
                  <b-tooltip :target="`weight-tooltip-${index}`" triggers="hover" no-fade>
                    <div>
                      <p><strong>1 Tonne:</strong>- 1kg to 999kg - 1 to 2 pallets</p>
                      <p><strong>3 Tonne:</strong>- 1000 kg to 2999kg - 3 to 4 pallets</p>
                      <p>
                        <strong>5 Tonne:</strong>- 3000 kg to 4999kg - 4 to 6 pallets (Depends on
                        lorry size)
                      </p>
                      <p><strong>10 Tonne:</strong>- 5000 kg to 9999kg - 7 to 8 pallets</p>
                      <p><strong>18 Tonne:</strong>- 10000kg to 17999kg - 9 to 10 pallets</p>
                      <br />
                    </div>
                  </b-tooltip>
                </slot>
                <v-select
                  v-model="form.lorry"
                  :placeholder="'Choose Lorry Size'"
                  :options="['1 tonne', '3 tonne', '5 tonne', '10 tonne', '18 tonne']"
                  :clearable="false"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group :label="'Pick Up Date'">
              <ValidationProvider v-slot="{ errors }" rules="required" name="Pick Up Date">
                <flat-pickr
                  v-model="form.pickup_date"
                  class="form-control"
                  placeholder="Choose Date"
                  readonly="readonly"
                  :config="{
                    minDate: 'today',
                  }"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <div class="d-flex align-items-center justify-content-center">
              <b-form-checkbox
                v-model="form.insurance"
                :checked="form.insurance === 1"
                class="mr-3"
              >
                Insurance
              </b-form-checkbox>
              <b-form-group :label="'Invoice Value'">
                <div v-if="form.insurance == 0">
                  <b-form-input
                    v-model="form.invoice"
                    :disabled="form.insurance == 0"
                    :placeholder="'Enter Invoice Value'"
                  />
                </div>
                <div v-else>
                  <ValidationProvider v-slot="{ errors }" rules="required" name="Invoice Value">
                    <b-form-input
                      v-model="form.invoice"
                      :disabled="form.insurance == 0"
                      :placeholder="'Enter Invoice Value'"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <app-collapse :accordion="true" type="margin" class="mb-1">
        <app-collapse-item
          v-for="(order_detail, index) in order_details"
          :key="index"
          :is-visible="true"
          title=""
        >
          <template #header>
            <div class="d-flex justify-content-center">
              <h4 class="text-primary mb-0 mr-1">
                {{ `Order ${index + 1}` }}
              </h4>
              <h5>
                Total Weight:
                {{ order_detail.total_weight ? order_detail.total_weight : 0 }}
              </h5>
              <h4 class="text-primary mx-1">|</h4>
              <h5>
                Total Quantity:
                {{ order_detail.total_quantity ? order_detail.total_quantity : 0 }}
              </h5>
            </div>
            <div>
              <b-button
                v-if="order_details.length > 1"
                variant="flat-primary"
                class="btn-icon mr-2"
                @click.prevent="removeRecipient(index)"
              >
                <feather-icon icon="TrashIcon" size="20" />
              </b-button>
            </div>
          </template>
          <div class="d-flex justify-content-center align-items-center mb-2">
            <hr class="flex-grow-1" />
            <span class="mx-2 font-weight-bold"> Recipient Details </span>
            <hr class="flex-grow-1" />
          </div>
          <b-row>
            <b-col md="12">
              <b-form-group :label="'Name (or Company Name)'">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required"
                  name="Name (or Company Name)"
                >
                  <input
                    v-model="order_detail.recipient.name"
                    type="text"
                    class="form-control mr-2"
                    placeholder="Name (or Company Name)"
                    aria-label="Name (or Company Name)"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Email">
                <ValidationProvider v-slot="{ errors }" rules="required|email" name="E-mail">
                  <input
                    v-model="order_detail.recipient.email"
                    type="text"
                    class="form-control mr-2"
                    placeholder="E-mail"
                    aria-label="E-mail"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="'Contact Number'">
                <ValidationProvider v-slot="{ errors }" rules="required" name="Contact Number">
                  <input
                    v-model="order_detail.recipient.phone"
                    type="text"
                    class="form-control mr-2"
                    placeholder="Contact Number"
                    aria-label="Contact Number"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group :label="'Unit Number'">
                <ValidationProvider v-slot="{ errors }" rules="required" name="Unit Number">
                  <input
                    v-model="order_detail.recipient.address.unitNumber"
                    type="text"
                    class="form-control mr-2"
                    placeholder="Unit Number"
                    aria-label="Unit Number"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group :label="'Address Line 1'">
                <ValidationProvider v-slot="{ errors }" rules="required" name="Address Line 1">
                  <input
                    v-model="order_detail.recipient.address.addressLine1"
                    type="text"
                    class="form-control mr-2"
                    placeholder="Address Line 1"
                    aria-label="Address Line 1"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group :label="'Address Line 2'">
                <ValidationProvider v-slot="{ errors }" rules="required" name="Address Line 2">
                  <input
                    v-model="order_detail.recipient.address.addressLine2"
                    type="text"
                    class="form-control mr-2"
                    placeholder="Address Line 2"
                    aria-label="Address Line 2"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group :label="'Postal Code'">
                <ValidationProvider v-slot="{ errors }" rules="required" name="Postal Code">
                  <input
                    v-model="order_detail.recipient.address.postalCode"
                    type="text"
                    class="form-control mr-2"
                    placeholder="Postal Code"
                    aria-label="Postal Code"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="'Area'">
                <ValidationProvider v-slot="{ errors }" rules="required" name="Area">
                  <input
                    v-model="order_detail.recipient.address.area"
                    type="text"
                    class="form-control mr-2"
                    placeholder="Area"
                    aria-label="Area"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group :label="'State'">
                <ValidationProvider v-slot="{ errors }" rules="required" name="State">
                  <v-select
                    v-model="order_detail.recipient.address.state"
                    :options="states"
                    :clearable="false"
                    label="text"
                    :reduce="(state) => state.value"
                    placeholder="Select a State"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="'Country'">
                <ValidationProvider v-slot="{ errors }" rules="required" name="Country">
                  <v-select
                    v-model="order_detail.recipient.address.country"
                    :options="countries"
                    :clearable="false"
                    label="text"
                    :reduce="(countries) => countries.value"
                    placeholder="Select a Country"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <hr />
          </b-row>

          <!-- Order Details -->
          <div v-for="(item, itemIndex) in order_detail.item_details" :key="itemIndex">
            <div class="d-flex justify-content-center align-items-center">
              <hr class="flex-grow-1" />
              <span class="mx-2 font-weight-bold"> Item Details {{ itemIndex + 1 }}</span>
              <hr class="flex-grow-1" />
            </div>
            <div class="d-flex justify-content-end">
              <b-button
                v-if="itemIndex != 0"
                variant="warning"
                @click="removeItem(index, itemIndex)"
                ><feather-icon icon="XIcon" size="14" /> Remove Item {{ itemIndex + 1 }}</b-button
              >
            </div>
            <b-row class="mb-3">
              <b-col md="4">
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Invoice Number">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                        name="Invoice Number"
                      >
                        <b-form-input
                          v-model="item.invoice_number"
                          type="text"
                          placeholder="Enter invoice number"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Enter PO number">
                      <ValidationProvider v-slot="{ errors }" rules="required" name="PO Number">
                        <b-form-input
                          id="po-number"
                          v-model="item.po_number"
                          type="text"
                          placeholder="Enter PO number"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="PO Expiry Date">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                        name="PO Expiry Date"
                      >
                        <flat-pickr
                          id="po-expiry-date"
                          v-model="item.po_expiry_date"
                          placeholder="YYYY-MM-DD HH:MM"
                          class="form-control"
                          :config="{ minDate: defaultPoExpiryDateTime }"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="4" class="px-lg-5">
                <b-row>
                  <b-col md="12">
                    <b-form-group label="Select Product Category">
                      <ValidationProvider v-slot="{ errors }" rules="required" name="Category">
                        <b-form-radio
                          v-for="option in categories"
                          :key="option.value"
                          v-model="item.category_id"
                          :value="option.value"
                        >
                          {{ option.text }}
                        </b-form-radio>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Select Packaging">
                      <ValidationProvider v-slot="{ errors }" rules="required" name="Packaging">
                        <b-form-radio
                          v-for="option in packaging"
                          :key="option.value"
                          v-model="item.selectedPackaging"
                          :value="option.text"
                        >
                          {{ option.text }}
                        </b-form-radio>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <div v-if="item.selectedPackaging === 'Others'">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :vid="`${'packaging'}`"
                          name="Packaging"
                        >
                          <b-form-input
                            v-model="item.custom_package"
                            placeholder="Others"
                            class="mt-1"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Select Remarks">
                      <ValidationProvider v-slot="{ errors }" rules="required" name="Remarks">
                        <b-form-radio
                          v-for="option in remarks"
                          :key="option.value"
                          v-model="item.selectedRemarks"
                          :value="option.value"
                        >
                          {{ option.text }}
                        </b-form-radio>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <div v-if="item.selectedRemarks === 'Others'" md="6" class="pt-0">
                        <ValidationProvider
                          v-slot="{ errors }"
                          :vid="`${'remarks'}`"
                          name="Remarks"
                        >
                          <b-form-input
                            v-model="item.custom_remarks"
                            class="mt-1"
                            placeholder="Other"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="4">
                <b-form-group label="SKU">
                  <ValidationProvider v-slot="{ errors }" rules="required" name="SKU">
                    <b-form-input
                      :id="`sku-${index}`"
                      v-model="item.sku"
                      type="text"
                      placeholder="Enter SKU"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group label="Temperature">
                  <ValidationProvider v-slot="{ errors }" rules="required" name="Temperature">
                    <v-select
                      v-model="item.temperature"
                      placeholder="Select Temperature"
                      :options="['Ambience', 'Chill', 'Frozen']"
                      :clearable="false"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group :label="'Dimension'">
                  <ValidationProvider v-slot="{ errors }" rules="required|min_value:1" name="Size">
                    <b-input-group>
                      <b-form-input
                        :id="`length-${index}`"
                        v-model="item.dimension.length"
                        type="number"
                        class="border-right-0 text-center px-0"
                        :placeholder="`0`"
                      />
                      <b-input-group-text class="border-right-0 border-left-0 rounded-0 p-0">
                        <feather-icon icon="XIcon" />
                      </b-input-group-text>
                      <b-form-input
                        :id="`width-${index}`"
                        v-model="item.dimension.width"
                        type="number"
                        class="border-right-0 border-left-0 rounded-0 text-center px-0"
                        :placeholder="`0`"
                      />
                      <b-input-group-text class="border-right-0 border-left-0 rounded-0 p-0">
                        <feather-icon icon="XIcon" />
                      </b-input-group-text>
                      <b-form-input
                        :id="`height-${index}`"
                        v-model="item.dimension.height"
                        type="number"
                        class="border-left-0 text-center px-0"
                        :placeholder="`0`"
                      />
                      <select
                        id="size-unit"
                        v-model="item.dimension_unit"
                        class="custom-select append-left"
                      >
                        <option value="cm" selected>cm</option>
                        <option value="in">in</option>
                        <option value="ft">ft</option>
                      </select>
                    </b-input-group>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
                <b-row class="d-flex align-items-center">
                  <b-col md="4">
                    <b-form-group>
                      <slot name="label">
                        Packed with pallet
                        <feather-icon
                          :id="`size-tooltip-${index}`"
                          style="margin-bottom: 5px"
                          size="20"
                          icon="InfoIcon"
                          :variant="'primary'"
                        />
                        <b-tooltip :target="`size-tooltip-${index}`" triggers="hover" no-fade>
                          Is your items packed with pallet?
                        </b-tooltip>
                      </slot>
                      <b-form-radio-group v-model="item.packedWithPallets">
                        <b-form-radio value="yes"> Yes </b-form-radio>
                        <b-form-radio value="no"> No </b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>

                  <b-col md="5">
                    <b-form-group
                      :label="
                        item.packedWithPallets === 'yes' ? 'Quantity of Pallets' : 'Enter quantity'
                      "
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required|min_value:1"
                        name="Quantity"
                      >
                        <b-form-input
                          v-model="item.qty"
                          type="number"
                          placeholder="Enter quantity"
                          @input="quantityCalculate(index)"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group :label="'Weight'">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required|min_value:1"
                        name="Weight"
                      >
                        <b-input-group append="kg">
                          <b-form-input
                            v-model="item.weight"
                            type="number"
                            :placeholder="'Enter weight'"
                            @input="weightCalculate(index)"
                          />
                        </b-input-group>
                        <span class="text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <hr />
          <!-- <div class="d-flex justify-content-center">
            <b-button variant="info" class="my-2" @click="addItem(index)">
              <feather-icon icon="PlusIcon" size="14" /> Add Item</b-button
            >
          </div> -->
        </app-collapse-item>
      </app-collapse>
      <div class="d-flex justify-content-center">
        <b-button variant="primary" class="my-2" @click="addRecipient">
          <feather-icon icon="PlusIcon" size="14" /> Add Order</b-button
        >
      </div>
      <div class="d-flex justify-content-end">
        <h5 class="mr-2" color="primary">
          Total Weight: {{ form.total_weight ? form.total_weight : 0 }}
        </h5>
        <h5 color="primary">Total Quantity: {{ form.total_quantity ? form.total_quantity : 0 }}</h5>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'Air',
  components: {
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    remarks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'mr-2',
      },
      countries: [
        { text: 'Malaysia', value: 'Malaysia' },
        { text: 'Singapore', value: 'Singapore' },
      ],
      states: [
        { text: 'Kuala Lumpur', value: 'Kuala Lumpur' },
        { text: 'Perlis', value: 'Perlis' },
        { text: 'Kedah', value: 'Kedah' },
        { text: 'Pulau Pinang', value: 'Pulau Pinang' },
        { text: 'Perak', value: 'Perak' },
        { text: 'Pahang', value: 'Pahang' },
        { text: 'Kelantan', value: 'Kelantan' },
        { text: 'Terengganu', value: 'Terengganu' },
        { text: 'Selangor', value: 'Selangor' },
        { text: 'Negeri Sembilan', value: 'Negeri Sembilan' },
        { text: 'Melaka', value: 'Melaka' },
        { text: 'Johor', value: 'Johor' },
        { text: 'Sabah', value: 'Sabah' },
        { text: 'Sarawak', value: 'Sarawak' },
        { text: 'Singapore', value: 'Singapore' },
      ],
      form: {
        lorry: '',
        pickup_date: '',
        insurance: 0,
        invoice: '',
        order_details: {},
        total_weight: 0,
        total_quantity: 0,
      },
      packaging: [
        { value: 1, text: 'Carton' },
        { value: 2, text: 'Foam Box' },
        { value: 3, text: 'Plastic' },
        { value: 4, text: 'Crate' },
        { value: 5, text: 'Basket' },
        { value: 0, text: 'Others' },
      ],
      order_details: [
        {
          recipient: { address: {} },
          total_weight: 0,
          total_quantity: 0,
          item_details: [
            {
              invoice_number: '',
              po_number: '',
              po_expiry_date: null,
              category_id: '',
              selectedPackaging: '',
              custom_package: '',
              selectedRemarks: '',
              custom_remarks: '',
              sku: '',
              temperature: '',
              dimension: { length: '', width: '', height: '' },
              dimension_unit: 'cm',
              lorry: '',
              packedWithPallets: 'no',
              qty: '',
              weight: '',
            },
          ],
        },
      ],
    }
  },

  computed: {
    defaultPoExpiryDateTime() {
      const today = new Date()
      today.setDate(today.getDate() + 1)
      return today
    },
  },

  methods: {
    childMethod() {
      return new Promise((resolve, reject) => {
        let result = ''
        this.form.order_details = this.order_details
        this.$refs.recipientDetails.validate().then((success) => {
          this.$emit('setForm', this.form)
          if (success) {
            result = true
            resolve(result)
          } else {
            result = false
          }
          resolve(result)
        })
      })
    },

    addItem(index) {
      this.order_details[index].item_details.push({
        invoice_number: '',
        po_number: '',
        po_expiry_date: null,
        category_id: '',
        selectedPackaging: '',
        custom_package: '',
        selectedRemarks: '',
        custom_remarks: '',
        sku: '',
        temperature: '',
        dimension: { length: '', width: '', height: '' },
        dimension_unit: 'cm',
        lorry: '',
        packedWithPallets: 'no',
        qty: '',
        weight: '',
      })
    },

    removeItem(index, itemIndex) {
      this.order_details[index].total_weight =
        parseFloat(this.order_details[index].total_weight) -
        this.order_details[index].item_details[itemIndex].weight
      this.order_details[index].total_quantity =
        parseFloat(this.order_details[index].total_quantity) -
        this.order_details[index].item_details[itemIndex].quantity
      this.order_details[index].item_details.splice(itemIndex, 1)
    },

    addRecipient() {
      this.order_details.push({
        recipient: { address: {} },
        total_weight: 0,
        total_quantity: 0,
        item_details: [
          {
            invoice_number: '',
            po_number: '',
            po_expiry_date: null,
            category_id: '',
            selectedPackaging: '',
            custom_package: '',
            selectedRemarks: '',
            custom_remarks: '',
            sku: '',
            temperature: '',
            dimension: { length: '', width: '', height: '' },
            dimension_unit: 'cm',
            lorry: '',
            packedWithPallets: 'no',
            qty: '',
            weight: '',
          },
        ],
      })
    },

    removeRecipient(index) {
      this.form.total_weight =
        parseFloat(this.form.total_weight) - parseFloat(this.order_details[index].total_weight)
      this.form.total_quantity =
        parseFloat(this.form.total_quantity) - parseFloat(this.order_details[index].total_quantity)
      this.order_details.splice(index, 1)
    },

    getWeightRange(index, itemIndex) {
      const lorryType = this.order_details[index].item_details[itemIndex].lorry
      switch (lorryType) {
        case '1 tonne':
          return { min: 1, max: 1000 }
        case '3 tonne':
          return { min: 1001, max: 3000 }
        case '5 tonne':
          return { min: 3001, max: 5000 }
        case '10 tonne':
          return { min: 5001, max: 10000 }
        default:
          return { min: 0, max: 0 }
      }
    },

    weightCalculate(index) {
      this.order_details[index].total_weight = 0
      for (let y = 0; y < this.order_details[index].item_details.length; y++) {
        this.order_details[index].total_weight += parseFloat(
          this.order_details[index].item_details[y].weight
        )
      }

      this.form.total_weight = 0
      for (let y = 0; y < this.order_details.length; y++) {
        this.form.total_weight += parseFloat(this.order_details[y].total_weight)
      }
    },

    quantityCalculate(index) {
      this.order_details[index].total_quantity = 0
      for (let y = 0; y < this.order_details[index].item_details.length; y++) {
        this.order_details[index].total_quantity += parseFloat(
          this.order_details[index].item_details[y].qty
        )
      }

      this.form.total_quantity = 0
      for (let y = 0; y < this.order_details.length; y++) {
        this.form.total_quantity += parseFloat(this.order_details[y].total_quantity)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
