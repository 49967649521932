<template>
  <div>
    <div v-if="!previewUrl" class="w-100 d-flex justify-content-center">
      <label
        for="receipt-input"
        class="w-50 d-flex justify-content-center hover-overlay text-center border-dash rounded"
      >
        <div class="p-5">
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon icon="UploadIcon" size="30" />
          </div>
          <h4 class="mt-1">Upload Receipt</h4>
          <p class="text-info">Support: Image & PDF only</p>
        </div>
      </label>
    </div>

    <div v-else>
      <div class="container full-height d-flex justify-content-center align-items-center">
        <div class="d-flex flex-column">
          <!-- Display the file name if an image is selected -->
          <p>
            Selected File:
            <b> {{ fileName }} </b>
          </p>
          <!-- Image preview (only if the file is an image) -->
          <div>
            <p>File Preview:</p>
            <iframe v-if="isPDF" :src="previewUrl" width="350" height="350" />
            <img
              v-else
              :src="previewUrl"
              alt="File Preview"
              class="img-fluid"
              style="max-width: 350px; max-height: 350px"
            />
          </div>
        </div>
      </div>
      <!-- CHANGE RECEIPT  -->
      <div class="d-flex justify-content-center mt-2">
        <label
          for="receipt-input"
          class="w-25 form-control text-center border-primary text-primary"
        >
          <feather-icon icon="RefreshCwIcon" size="12" style="margin-right: 1px" />
          Change Receipt
        </label>
      </div>
    </div>

    <input
      id="receipt-input"
      required
      type="file"
      class="hidden"
      accept="image/*,.pdf"
      @change="onFileChange"
    />
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      // To store the file object
      fileName: '', // To store the file name
      previewUrl: '', // To store the image preview URL
      isPDF: false,
    }
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0] // Get the first selected file
      if (file && (file.type.startsWith('image/') || file.type === 'application/pdf')) {
        this.fileName = file.name
        this.isPDF = false
        if (file.type === 'application/pdf') {
          this.isPDF = true
        }

        // Create a FileReader to read the file and generate a preview URL
        const reader = new FileReader()
        reader.onload = (e) => {
          this.previewUrl = e.target.result // Set the preview URL
        }
        reader.readAsDataURL(file) // Read the file as a data URL for image preview
        this.$emit('handleReceipt', file)
      } else {
        this.$emit('handleReceipt', null)
        this.previewUrl = '' // Clear preview if not an image
        this.$emit('handleWrongReceipt')
      }
    },
  },
}
</script>

<style lang="scss">
.border-dash {
  border: 2px dashed #5c5b5b;
  margin: 20px 0;
}
</style>
